import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './navigation.scss'
import data from './applicatioLinks.json'
import { ApplicationLink, ApplicationLinkResult } from '../../interface/applicationlink';
import helperFunctions from '../../utils/helperfunctions';
import humburg from '../../assets/images/hamburger.svg'

const Navigation = (props: any) => {
    
    const { headerNav, onToggleHeaderMenu } = props
    const { pathname } = window.location;
    const splitLocation = pathname.split("/");
    const [applicationLinks, setApplicationLinksInfo] = useState([] as ApplicationLink[]);
    
    useEffect(() => {
        let linkjson1 = JSON.stringify(data);
        let linkjson2 = JSON.parse(linkjson1) as ApplicationLinkResult;
        setApplicationLinksInfo(linkjson2?.links.filter((item: { section: string; }) => item?.section == "Web-Menu"));
    }, []);
   
 function ContructNavMenus(menuItem: ApplicationLink) {
        let subMenus = menuItem?.children?.sort((a, b) => a.displayorder - b.displayorder);
        return (
            <li className={subMenus?.length > 0 ? 'DropdownIcon' : ''}>
                <Link to='#' onClick={() => { helperFunctions.MenuClickEvent(menuItem.fullurl, menuItem.target) }} className={splitLocation[1].toLocaleLowerCase() === menuItem?.title?.toLocaleLowerCase() ? "active" : ""} title={menuItem?.title}>{menuItem?.title}</Link>
                <ul>
                    {
                        subMenus?.map(function (item, index) {
                            return <React.Fragment key={index} > {(ContructNavMenus(item))}</React.Fragment>
                        })
                    }
                </ul>
            </li>
        )
    }

    return (
        <div className="navigation-wrapper">
            <span
                className='fa fa-bars menuBars'
                onClick={() => onToggleHeaderMenu()}
            ><img src={humburg} /></span>
            {(headerNav &&
                <nav className='headerNavigation'>
                    <ul>
                        {applicationLinks?.map(function (item, index) {
                            return <React.Fragment key={item.section + item.displayorder}>{ContructNavMenus(item)}</React.Fragment>
                        })}
                    </ul>
                </nav>
            )}

            <div className='clear-both' />
        </div>
    )
};

export default Navigation