import React from "react";
import facebooksvg from "../../assets/images/facebook.svg";
import twitter from "../../assets/images/twitter.svg";
import linkedinsvg from "../../assets/images/profile.png";
import youtube from "../../assets/images/youtube.svg";
import "./footer.scss";
import { ApplicationLink } from "../../interface/applicationlink";
import helperFunctions from "../../utils/helperfunctions";


const Footer = (props: any) => { 


  return (
      <div className="footer">
          <div className="stackStylesFooter1">
        <div className="container-fluid mx-2">
          <div className="row">
            <div className="col-12 col-lg-4 footerLeft">
               All Rights Reserved. Tracksen.com
            </div>
            <div className="col-12 col-lg-4 footerCenter">
              <a
                href="#"
                title={props.privacypolicy?.title}
                className=""
                onClick={() => {                 
                  helperFunctions.MenuClickEvent(
                    props.privacypolicy.fullurl,
                    props.privacypolicy.target
                  );
                }}
              >
                {props.privacypolicy.title}
              </a>{" "}
              <span>|</span>
              <a
                href="#"
                title={props.termsofuse?.title}
                onClick={() => {                 
                  helperFunctions.MenuClickEvent(
                    props.termsofuse.fullurl,
                    props.termsofuse.target
                  );
                }}
              >
                {props.termsofuse.title}
              </a>
            </div>
            <div className="col-12 col-lg-4 footerRight">
                          <div className="socilaMedia">
                              {props.socialnwPreFooter?.map(function (
                                  item: ApplicationLink,
                                  index: any
                              ) {
                                  let imagesrc = "";
                                  let imageType = item.name?.toLowerCase();
                                  if (imageType.includes("facebook"))
                                      imagesrc = facebooksvg;
                                  else if (imageType.includes("twitter"))
                                      imagesrc = twitter;
                                  else if (imageType.includes("linkedin"))
                                      imagesrc = linkedinsvg;
                                  else if (imageType.includes("youtube"))
                                      imagesrc = youtube;
                                  return (
                                      <img
                                          src={imagesrc}
                                          key={item.fullurl}
                                          onClick={() => {
                                              helperFunctions.MenuClickEvent(item.fullurl, item.target);
                                          }}
                                          alt={item.title}
                                          title={item.title}
                                          className="socilaMediaImg"
                                      />
                                  );
                              })}
                          </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};
export default Footer;
