import React, {
    useEffect,
    useState
} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { DataGrid, GridColDef, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid';
import './bookinglist.scss'


const BookingList = () => {

    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
            .then((resp) => resp.json())
            .then((data) => setRowData(data));
    }, []);

    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState<any>();

    const handleEdit = (row: any) => {
        setSelectedRow(row);
        setEditDialogOpen(true);
    };
    const handleDelete = (row: any) => {
        //fdgfddf
    };
    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        setSelectedRow({});
    };

    const handleSaveEdit = () => {
        // Handle the save edit action
        console.log(`Save edit for row with ID ${selectedRow?.id}`);
        handleEditDialogClose();
    };


    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'firstName',
            headerName: 'First name',
            width: 150,
            editable: true,
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,
            editable: true,
        },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            width: 110,
            editable: true,
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <div>
                    <Button style={{ margin: '10px' }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEdit(params.row)}
                    >
                        Edit
                    </Button>
                    <Button style={{ margin: '10px' }}
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleDelete(params.row)}
                    >
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    return (
        <div className="main-wrapper">
            <h1> Welcome to Booking List</h1>
            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
                disableDensitySelector
                //disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                slots={{
                    toolbar: GridToolbar,
                }}
            />



            {/* Edit Dialog */}
            <Dialog open={editDialogOpen} onClose={handleEditDialogClose} className="App">
                <DialogTitle>Edit Row</DialogTitle>
                <DialogContent>
                    <TextField
                        label="First Name"
                        value={selectedRow?.firstName || ''}
                        onChange={(e) =>
                            setSelectedRow((prevRow: any) => ({
                                ...prevRow,
                                firstName: e.target.value,
                            }))
                        }
                    />
                    <TextField
                        label="Last Name"
                        value={selectedRow?.lastName || ''}
                        onChange={(e) =>
                            setSelectedRow((prevRow: any) => ({
                                ...prevRow,
                                lastName: e.target.value,
                            }))
                        }
                    />
                    <TextField
                        label="Age"
                        type="number"
                        value={selectedRow?.age || 0}
                        onChange={(e) =>
                            setSelectedRow((prevRow: any) => ({
                                ...prevRow,
                                age: parseInt(e.target.value, 10) || 0,
                            }))
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose}>Cancel</Button>
                    <Button className="primarybutton" onClick={handleSaveEdit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>




        </div>
    )
}
export default BookingList;