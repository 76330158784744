import React from 'react';
import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-svg-core/styles.css';
import Login from '../login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from '../header/header';
import Navigation from '../navigation';
import Footer from '../footer';
import BookingList from '../booking';

function App() {
    const windowHcalc = window.innerHeight - 125;

    return (   
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />}></Route>
            <Route path="/booking" element={<>
                <div className="layout">
                    <Header />
                    <Navigation /> 
                    <main>
                        <BookingList />  
                    </main>
                    <Footer />
                </div>  
                </>
                }>
                </Route>
            </Routes> 
        </BrowserRouter>
    );
}

export default App;
