import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Hamburger } from "../../assets/images/hamburger.svg";
import '@fortawesome/fontawesome-svg-core/styles.css';
import logo from "../../assets/images/MainLogo.png";
import profileicon from "../../assets/images/profile.png";
import {
    Button,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover } from "@fluentui/react-components";
import './header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Navigation from '../navigation';
import Footer from '../footer';

const Header = () => {
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    const signout = () => {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = "/";
    }

    return (

        <div className="container-fluid header-wrapper">
               <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="leftList">
                        <img src={logo} alt="logo" className="logoImg" />
                        </div>
                    </div> 
                    <div className="col-12 col-lg-8">
                        <div className="rightList">
                            <ul className="inlineList">                          
                            <li>
                                <img src={profileicon} className="userList"/>                              
                            </li>  
                            <div className="UserA">
                                <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <Button><span>Dhanesh, Kumar</span>
                                        <span className="userProfile_downarrow"></span>
                                    </Button>
                                </MenuTrigger>
                                    <MenuPopover >
                                        <MenuList>
                                            <MenuItem onClick={signout}>Signout</MenuItem>                                 
                                    </MenuList>
                                </MenuPopover>
                                </Menu>
                            </div>
                            </ul>
                        </div>
                    </div>
            </div>        
            </div>

        




        
        //<nav className="navbar">

        //    <div className="container">
        //        <div >
        //            <img src={logo} className="logo" />
        //        </div>
        //        <div className="menu-icon" onClick={handleShowNavbar}>
        //            <Hamburger />
        //        </div>
        //        <div className={`nav-elements  ${showNavbar && 'active'}`}>
        //            <ul>
        //                <li>
        //                    <NavLink to="/">Home</NavLink>
        //                </li>
        //                <li>
        //                    <NavLink to="/blog">Blog</NavLink>
        //                </li>
        //                <li>
        //                    <NavLink to="/projects">Projects</NavLink>
        //                </li>
        //                <li>
        //                    <NavLink to="/about">About</NavLink>
        //                </li>
        //                <li>
        //                    <NavLink to="/contact">Contact</NavLink>
        //                </li>
        //            </ul>
        //        </div>
        //    </div>
        //</nav>
    )
}

export default Header