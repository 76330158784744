import React, { useState, useEffect } from "react";
import Navigation from "./navigation";


const NavigationContainer = (props: any) => {
    const [headerNav, showHeaderNav] = useState(true)

    const onToggleHeaderMenu = () => {
        showHeaderNav(!headerNav)
    }

    useEffect(() => {
        const windowResize = () => {
            let width = window.innerWidth
            if (width < 600) {
                showHeaderNav(false)
            } else {
                showHeaderNav(true)
            }
        }
        windowResize()
        window.addEventListener('resize', windowResize)
    }, [])
    return (<Navigation
        headerNav={headerNav}
        userId={props?.userId}
        onToggleHeaderMenu={onToggleHeaderMenu}
    />
    )

}

export default NavigationContainer