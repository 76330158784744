import React, { useEffect, useState } from "react";
import "./footer.scss";
import Footer from "./footer";
import { ApplicationLink, ApplicationLinkResult } from "../../interface/applicationlink";
import data from '../navigation/applicatioLinks.json'

const FooterContainer = (props: any) => {
    const [footerLinks, setFooterLinksInfo] = useState([] as ApplicationLink[]);
    const [prefooterLinks, setPreFooterLinksInfo] = useState([] as ApplicationLink[]);

    let privacypolicy = {} as ApplicationLink;
    let termsofuse = {} as ApplicationLink;
    let socialnwPreFooter = [] as ApplicationLink[];

    if (footerLinks != null && footerLinks.length > 0) {
        privacypolicy = footerLinks?.filter(
            (item: { name: string }) => item?.name == "MyK_Footer_PrivacyPolicy"
        )[0];
        termsofuse = footerLinks?.filter(
            (item: { name: string }) => item?.name == "MyK_Footer_TermsofUse"
        )[0];
    }
    if (prefooterLinks != null && prefooterLinks.length > 0) {

        socialnwPreFooter = prefooterLinks?.filter(
            (item: { name: string }) =>
                item?.name == "PreFooter_SocialNetworks"
        )[0]
            ?.children.sort(
                (a: { displayorder: number }, b: { displayorder: number }) =>
                    a.displayorder - b.displayorder
            );
    }

    useEffect(() => {
        let linkjson1 = JSON.stringify(data);
        let linkjson2 = JSON.parse(linkjson1) as ApplicationLinkResult;
        setFooterLinksInfo(linkjson2?.links.filter((item: { section: string; }) => item?.section == "Web-Footer"));
        setPreFooterLinksInfo(linkjson2?.links.filter((item: { section: string; }) => item?.section == "Web-PreFooter"));
    }, []);


    return (
        <Footer
            socialnwPreFooter={socialnwPreFooter}
            privacypolicy={privacypolicy}
            termsofuse={termsofuse}
        />
    );
};
export default FooterContainer;
