import React from 'react';
import Login from './login';

const LoginContainer = () => {

    return (
        <Login />
    );

};

export default LoginContainer;